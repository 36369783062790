import { Box, Flex, List, ListItem } from '@terminal/design-system';
import Image from 'next/image';
import { Storyblok, JobCardStoryblok } from '../types';
import { storyblokEditable } from '@storyblok/react';

// not using the design system colors because
// these colors match the images used in the cards
const theme = {
  green: {
    color: '#2D7B57',
    bgColor: '#D2F5E4',
  },
  blue: {
    color: '#057992',
    bgColor: '#DEF3F7',
  },
  grey: {
    color: '#25383F',
    bgColor: '#F4F7F9',
  },
  yellow: {
    color: '#7D391C',
    bgColor: '#FFF0CB',
  },
};

export const JobCard = ({ blok }: Storyblok<JobCardStoryblok>) => {
  const skills = blok.skills.split(',').map((item) => item.trim());
  return (
    <Box
      p="8"
      boxShadow="-0.0625rem 0.25rem 1rem rgba(0, 0, 0, 0.12)"
      bgColor="white"
      borderRadius="1rem"
      minH="25.25rem"
      {...storyblokEditable(blok)}
    >
      <Flex alignItems="flex-start" justifyContent="space-between">
        <Image
          src={blok.icon.filename}
          alt=""
          width="52"
          height="52"
          style={{ objectFit: 'contain' }}
          layout="intrinsic"
        />
        {blok.salary && (
          <Box
            fontSize="0.875rem"
            lineHeight="1rem"
            fontWeight="medium"
            bgColor="bg.secondary"
            textAlign="center"
            px="4"
            py="2"
            borderRadius="2.5625rem"
          >
            {blok.salary}
          </Box>
        )}
      </Flex>
      <Box mt="4" fontSize="1.75rem" lineHeight="1" fontWeight="semibold">
        {blok.role}
      </Box>
      <Box fontSize="1.125rem" lineHeight="1.5rem">
        <Box as="span" fontWeight="700">
          Industry:
        </Box>{' '}
        {blok.industry}
      </Box>
      <List mt="4">
        {skills.map((item, index) => (
          <ListItem
            key={index}
            display="inline-block"
            mb="3"
            mr="3"
            py="0.625rem"
            px="4"
            {...theme[blok.theme]}
            borderRadius="2.5625rem"
            fontWeight="500"
            fontSize="sm"
            lineHeight="1rem"
          >
            {item}
          </ListItem>
        ))}
      </List>
      <Box fontSize="1rem" lineHeight="1.25rem">
        {blok.description}
      </Box>
    </Box>
  );
};
