import { storyblokEditable } from '@storyblok/react';
import { Container, Box, Grid, GridItem, Text } from '@terminal/design-system';
import type { TestimonialSectionStoryblok, Storyblok } from '../types';
import { selectBlokStyles } from '@helpers/storyblok.utils';
import Testimonial from '@components/UI/Testimonial';
import Image from 'next/image';

export function TestimonialSection({ blok }: Storyblok<TestimonialSectionStoryblok>) {
  const WrapperComponent = blok.isFullWidth ? Box : Container;
  const variant = blok.variant || 'default';
  const { marginStyles } = selectBlokStyles(blok);

  return (
    <WrapperComponent
      as="section"
      my="0"
      p={0}
      {...marginStyles}
      {...storyblokEditable(blok)}
      {...(blok.isFullWidth ? { bgColor: blok.isDark ? 'grey.900' : 'bg.tertiary' } : {})}
    >
      <Container p={0}>
        {variant === 'default' ? (
          <Testimonial
            testimonial={{
              bgDark: blok.isDark,
              image: {
                sourceUrl: blok.authorPhoto.filename,
                altText: blok.authorPhoto.alt,
              },
              text: blok.message,
              author: {
                name: blok.author,
                jobTitle: blok.authorDescription,
              },
            }}
            pt="16"
            pb="20"
            px={{ base: '16', lg: '20' }}
          />
        ) : (
          <LargeImageVariant blok={blok} />
        )}
      </Container>
    </WrapperComponent>
  );
}

function LargeImageVariant({ blok }: { blok: TestimonialSectionStoryblok }) {
  return (
    <Grid
      templateColumns={{
        lg: '1fr 1fr',
      }}
      gap={{ base: '16', lg: '10rem' }}
      py={{ base: '12', lg: '20' }}
    >
      <GridItem>
        <Box textAlign="center">
          <Image
            src={blok.authorPhoto.filename}
            alt={blok.authorPhoto.alt}
            width="490"
            height="344"
            style={{ objectFit: 'contain' }}
          />
        </Box>
      </GridItem>
      <GridItem>
        <Text
          mt={{ base: '4', xl: '2.625rem' }}
          fontWeight={{ lg: 'semibold' }}
          fontSize={{ base: '1.125rem', xl: '1.75rem' }}
          lineHeight={{ base: '1.5rem', xl: '2.125rem' }}
        >
          {blok.message}
        </Text>
        <Box w="100%" h="px" bgColor="grey.400" my={{ base: '4', lg: '6' }} />
        <Box
          fontWeight="semibold"
          fontSize={{ base: '1rem', xl: '1.5rem' }}
          lineHeight={{ base: '1.25rem', xl: '1.75rem' }}
        >
          {blok.author}
        </Box>
        <Box
          fontSize={{ base: '1rem', xl: '1.125rem' }}
          lineHeight={{ base: '1.25rem', xl: '1.5rem' }}
        >
          {blok.authorDescription}
        </Box>
      </GridItem>
    </Grid>
  );
}
