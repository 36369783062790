// @ts-ignore
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { _ as mpUtil } from 'mixpanel-browser';
import Cookies from 'js-cookie';
import getConfig from 'next/config';
import { SegmentConsent, requiresConsent } from './SegmentConsent';

const { publicRuntimeConfig } = getConfig();

export type CookieCampaignInfo = {
  utm_campaign?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_term?: string;
  utm_content?: string;
  cid?: string;
  tracking_id?: string;
};

export function parse_utm_querystring_to_object(
  utm_cookie_params_querystring: string,
): CookieCampaignInfo {
  return utm_cookie_params_querystring.split('&').reduce((acc, pair) => {
    const [key, value] = pair.split('=');
    if (value == null || value == undefined || value == '') return acc;
    acc[key as keyof CookieCampaignInfo] = value;
    return acc;
  }, {});
}

/**
 * This function creates and manages campaign UTC parameters.
 * @function
 * @param {Object} campaign_params - An object containing the following properties:
 *   - utm_campaign {string}
 *   - utm_source {string}
 *   - utm_medium {string}
 *   - utm_term {string}
 *   - utm_content {string}
 * @returns {Object} - An object containing the campaign parameters, either from the provided campaignParams object or from cookie if available.
 */
function createCampaignParams() {
  const utm_cookie_params_querystring: string = Cookies.get('utm_cookie_params') ?? '';

  return parse_utm_querystring_to_object(utm_cookie_params_querystring);
}

export const getContext = () => {
  const mpProperties = mpUtil.info.properties();
  const campaignParams = createCampaignParams();

  const campaignFiltered = Object.fromEntries(
    Object.entries({
      name: campaignParams.utm_campaign,
      source: campaignParams.utm_source,
      medium: campaignParams.utm_medium,
      term: campaignParams.utm_term,
      content: campaignParams.utm_content,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    }).filter(([_, value]) => value !== null && value !== undefined && value !== ''),
  );

  return {
    os: {
      name: mpProperties.$os || null,
    },
    browser: {
      name: mpProperties.$browser || null,
      version: mpProperties.$browser_version || null,
    },
    device: {
      name: mpProperties.$device || null,
    },
    screen: {
      height: mpProperties.$screen_height || null,
      width: mpProperties.$screen_width || null,
    },
    campaign: campaignFiltered,
  };
};

function removeTerminalSuffix(str) {
  const suffix = ' - Terminal.io';
  if (str.endsWith(suffix)) {
    return str.slice(0, -suffix.length);
  }
  return str;
}

function convertCategory(str) {
  switch (str) {
    case 'businesses':
      return 'demand';
    case 'engineers':
      return 'supply';
    default:
      return str;
  }
}

function trackWithSegment({
  category,
  name,
  properties = {},
  context = {},
}: {
  category: 'businesses' | 'engineers';
  name: string;
  properties?: object;
  /**
   * When not provided, it will call getContext() and use its default values
   */
  context?: ReturnType<typeof getContext> | Record<string, never>;
}) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).analytics.page(
    convertCategory(category),
    removeTerminalSuffix(name),
    properties,
    {
      ...getContext(),
      ...context,
    },
  );
}

const SegmentContext = createContext<{
  canTrackUser: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  segmentPreferences: any;
  trackPage: (params: Parameters<typeof trackWithSegment>[number]) => void;
}>({
  canTrackUser: false,
  segmentPreferences: null,
  trackPage: trackWithSegment,
});

export function SegmentProvider({ children }) {
  const [canTrackUser, setCanTrackUser] = useState(false);
  const [segmentPreferences, setSegmentPreferences] = useState(null);

  const onReadyToInitializeTracking = useCallback((segmentPreferences) => {
    setSegmentPreferences(segmentPreferences);
  }, []);

  useEffect(() => {
    const segmentEvent = async () => {
      const consentRequired = await requiresConsent();
      setCanTrackUser(!consentRequired || segmentPreferences?.customPreferences?.functional);
    };

    if (segmentPreferences != null) {
      void segmentEvent();
    }
  }, [segmentPreferences]);

  return (
    <>
      <SegmentConsent onReadyToInitializeTracking={onReadyToInitializeTracking} />
      <SegmentContext.Provider
        value={{
          canTrackUser,
          segmentPreferences,
          trackPage: trackWithSegment,
        }}
      >
        {children}
      </SegmentContext.Provider>
    </>
  );
}

export function useSegment() {
  const context = useContext(SegmentContext);

  if (!context) {
    throw new Error('useSegment must be used within a SegmentProvider');
  }

  return context;
}
