import { Storyblok, TabsWithContentStoryblok } from '../types';
import {
  Box,
  Button,
  Container,
  Heading,
  Tabs,
  HStack,
  TabPanels,
  TabPanel,
} from '@terminal/design-system';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

import { motion } from 'framer-motion';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { useMultiStyleConfig, useTab } from '@chakra-ui/react';

const MotionBox = motion(Box);

export function TabsWithContent({ blok }: Storyblok<TabsWithContentStoryblok>) {
  const { title, tabs } = blok;

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '"></span>';
    },
  };

  return (
    <Box my={{ base: '12', lg: '24' }} id="about" {...storyblokEditable(blok)}>
      <Container>
        <Heading
          as="h2"
          textAlign="center"
          fontSize={{ base: '2.5rem', lg: '3rem' }}
          lineHeight={{ base: '3rem', lg: '3.5rem' }}
          mb={{ base: '4', lg: '6' }}
        >
          {title}
        </Heading>
        <Box display={{ lg: 'none' }}>
          <style global jsx>{`
            .swiper-horizontal .swiper-pagination {
              bottom: 0;
            }
            .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
              width: 1rem;
              height: 1rem;
              margin: 0 0.5rem;
              background-color: #43b97f;
            }
          `}</style>
          <Box
            as={Swiper}
            py="4"
            pb="16"
            pagination={pagination}
            modules={[Pagination, Autoplay]}
            autoplay={{
              delay: 10000,
              disableOnInteraction: false,
            }}
            speed={1000}
            autoHeight={true}
          >
            {tabs?.map((tab, index) => (
              <SwiperSlide key={index} style={{ width: '20.8125rem' }}>
                <Box key={tab.title} minH={{ base: '37.5rem', sm: '41.9375rem', md: '44.5rem' }}>
                  <Box>
                    <Box mb="6" position="relative">
                      {tab?.content?.map((blok) => (
                        <StoryblokComponent key={blok._uid} blok={blok} />
                      ))}
                    </Box>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Box>
        </Box>
        <CustomTabs tabs={tabs} />
      </Container>
    </Box>
  );
}

const CustomTabs = ({ tabs }: { tabs: any }) => {
  const CustomTab = (props) => {
    const tabProps = useTab({ ...props });
    const isSelected = !!tabProps['aria-selected'];
    const styles = useMultiStyleConfig('Tabs', tabProps);
    return (
      <Button
        __css={styles.tab}
        {...tabProps}
        py="0"
        px="0"
        textAlign="left"
        position="relative"
        border="none"
        _before={{
          content: '""',
          position: 'absolute',

          margin: '0 auto',
          bottom: '0',
          width: '100%',
          height: '0.125rem',
          bgColor: isSelected ? 'grey.900' : 'transparent',
        }}
        _active={{
          bgColor: 'transparent',
        }}
        _focus={{
          boxShadow: isSelected ? 'none' : 'var(--chakra-shadows-outline)',
        }}
      >
        <Box fontSize="1.125rem" lineHeight="10" color={isSelected ? 'grey.900' : 'grey.900'}>
          {props.title}
        </Box>
      </Button>
    );
  };

  return (
    <Box display={{ base: 'none', lg: 'block' }}>
      <Tabs isLazy id="tabs" variant="unstyled">
        <HStack spacing="4.625rem" justifyContent="center">
          {tabs.map((tab, index) => (
            <CustomTab key={index} title={tab.button} />
          ))}
        </HStack>
        <TabPanels>
          {tabs.map((tab, index) => (
            <TabPanel key={index} mt="9" overflow="hidden">
              <MotionBox
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
              >
                {(tab?.content ?? []).map((blok) => (
                  <StoryblokComponent key={blok._uid} blok={blok} />
                ))}
              </MotionBox>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
};
