import { Storyblok, FooterStoryblok } from '../types';
import BottomFooter from '@components/Footer/BottomFooter';
import FooterCTA from '@components/Footer/FooterCTA';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { Box, Container, Grid, Link } from '@terminal/design-system';
import TerminalLogo from '@components/UI/TerminalLogo';

export function Footer(
  props: Storyblok<FooterStoryblok> & {
    page: {
      footerCTA: {
        footerType: 'businesses' | 'engineers';
        withCTA: boolean;
        longText: boolean;
        text: string;
        image?: string;
        button?: {
          url: string;
          text: string;
        };
        outlineButton?: {
          url: string;
          text: string;
        };
      };
    };
  },
) {
  const { blok, page } = props;

  const footerCTA = page.footerCTA;
  return (
    <footer {...storyblokEditable(blok)}>
      {footerCTA?.withCTA ? <FooterCTA type={footerCTA.footerType} footer={footerCTA} /> : null}
      <Box
        bgColor="blue.100"
        pt={{ base: '14', md: '10', xl: '20' }}
        pb={{ base: '10', md: '16' }}
        color="text.inverse"
        {...storyblokEditable(blok)}
      >
        <Container>
          <Box display={{ xl: 'flex' }} alignItems={{ xl: 'flex-start' }}>
            <Link
              href="/"
              display="flex"
              alignItems="center"
              mr={{ xl: '36' }}
              w="fit-content"
              aria-label="Terminal logo"
            >
              <TerminalLogo
                width={{ base: '8.75rem', xl: '9.8125rem' }}
                height={{ base: '2.5625rem', xl: '2.875rem' }}
              />
            </Link>
            <Grid
              mt={{ base: '8', md: '8', xl: '0' }}
              templateColumns={{
                base: '1fr',
                md: `repeat(${Math.min(blok.sections.length, 4)}, 1fr)`,
              }}
              gap={{ base: '10', md: '14' }}
              flex={{ xl: '1' }}
            >
              {blok.sections?.map((section) => {
                return <StoryblokComponent key={section._uid} blok={section} />;
              })}
            </Grid>
          </Box>
        </Container>
      </Box>
      <BottomFooter />
    </footer>
  );
}
