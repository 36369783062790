export const fonts = {
  body: 'Manrope, sans-serif',
  heading: 'Manrope, sans-serif',
};

export const fontSizes = {
  xs: '0.75rem', // 12px
  sm: '0.875rem', // 14px
  md: '1rem', // 16px
  lg: '1.125rem', // 18px
  xl: '1.25rem', // 20px
  '2xl': '1.5rem', // 24px
  '3xl': '1.75rem', // 28px
  '4xl': '2.25rem', // 36px
  '5xl': '3rem', // 48px
  '6xl': '3.75rem', // 60px
  '7xl': '4.5rem', // 72px
};

export const fontWeights = {
  regular: 400,
  bold: 700,
};

export const lineHeights = {
  shorter: 1.25, // 125%     20px
  short: 1.375, // 137.5%   22px
  base: 1.5, // 150%     24px
};

export const radii = {
  none: '0', // 0
  sm: '0.125rem', // 2px
  base: '0.25rem', // 4px
  md: '0.375rem', // 6px
  lg: '0.5rem', // 8px
  xl: '0.75rem', // 12px
};

export const zIndices = {
  hide: -1,
  auto: 'auto',
  base: 0,
  docked: 10,
  dropdown: 1000,
  sticky: 1100,
  banner: 1200,
  overlay: 1300,
  modal: 1400,
  popover: 1500,
  skipLink: 1600,
  toast: 1700,
  tooltip: 1800,
};

// Used by padding, margin, and top, left, right, bottom
export const space = {
  0: '0',
  1: '0.25rem', // 4px
  2: '0.5rem', // 8px
  3: '0.75rem', // 12px
  4: '1rem', // 16px
  6: '1.5rem', // 24px
  8: '2rem', // 32px
  10: '2.5rem', // 40px
  12: '3rem', // 48px
  14: '3.5rem', // 56px
  16: '4rem', // 64px
  18: '4.5rem', // 72px
  20: '5rem', // 80px
  22: '5.5rem', // 88px
  21: '6rem', // 96px // TODO: fix: this should have been 24
  32: '8rem', // 128px
  40: '10rem', // 160px
  64: '16rem', // 256px
};

export const breakpoints = {
  sm: '48rem', // 768px mobile => 0-768px
  md: '62rem', // 992px tablet => 768px-992px
  lg: '74.25rem', // 1188px small desktop => 992px-1188px
  // 1188px large desktop => +1188px

  // we don't use the breakpoints below
  xl: '90em', // not using // 1440 px
  '2xl': '96em', // not using
};

// width, height, and maxWidth, minWidth, maxHeight, minHeight
export const sizes = {
  ...space,
  max: 'max-content',
  min: 'min-content',
  full: '100%',
  xs: '20rem', // 320 px
  sm: '25rem', // 400 px
  md: '30rem', // 480 px
  ml: '32rem', // 512 px
  lg: '35rem', // 560 px
  xl: '40rem', // 640 px
  '2xl': '45rem', // 720 px
  '3xl': '50rem', // 800 px
  '4xl': '55rem', // 880 px
  '5xl': '60rem', // 960 px
  container: {
    ...breakpoints,
  },
};

export const shadows = {
  // TODO: this is not in the design anymore
  outline: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', // the shadow use for focus state by chakra ui
  base: '0px 1px 3px rgba(0, 0, 0, 0.06)',
  md: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  dark: '0px 4px 8px rgba(0, 0, 0, 0.25)',
  reverse: {
    base: '0px -1px 3px rgba(0, 0, 0, 0.06)',
    md: '0px -4px 6px rgba(0, 0, 0, 0.1)',
  },
};

const ui = {
  error: {
    lightest: '#FEF0EC',
    lighter: '#FEC9BD',
    main: '#F6623A',
    darker: '#842813',
    darkest: '#3A0F03',
  },
  warning: {
    lightest: '#FEF8EB',
    lighter: '#FFECBD',
    main: '#F6AE2D',
    darker: '#9A4B1D',
    darkest: '#3A2903',
  },
  success: {
    lightest: '#F5F8F1',
    lighter: '#CEE9AF',
    main: '#96BA6E',
    darker: '#546D3C',
    darkest: '#2B361B',
  },
  info: {
    lightest: '#E2F0F4',
    lighter: '#BADFF4',
    main: '#84CAF1',
    darker: '#4FA7BA',
    darkest: '#295B66',
  },
};

export const colors = {
  brand: {
    lightest: '#EBEBEB',
    lighter: '#7A7A7A',
    main: '#585858',
    darker: '#3D3D3D',
    darkest: '#1F1F1F',
  },
  accent: {
    lightest: '#F1E4F2',
    lighter: '#C792C9',
    main: '#AD5DB0',
    darker: '#864389',
    darkest: '#432244',
  },
  ui: {
    primary: '#25383F',
    secondary: '#D5DADC',
    disabled: '#EEF0F1',
    error: ui.error.main,
    success: ui.success.main,
    warning: ui.warning.main,
    info: ui.info.main,
    inverse: {
      primary: '#FFFFFF',
      secondary: '#F4F7F9',
    },
    lightest: {
      error: ui.error.lightest,
      success: ui.success.lightest,
      warning: ui.warning.lightest,
      info: ui.info.lightest,
    },
    lighter: {
      error: ui.error.lighter,
      success: ui.success.lighter,
      warning: ui.warning.lighter,
      info: ui.info.lighter,
    },
    darker: {
      error: ui.error.darker,
      success: ui.success.darker,
      warning: ui.warning.darker,
      disabled: '#AFAFAF', // remove?
      info: ui.info.darker,
    },
    darkest: {
      error: ui.error.darkest,
      success: ui.success.darkest,
      warning: ui.warning.darkest,
      info: ui.info.darkest,
    },
  },
  bg: {
    primary: '#FFFFFF',
    secondary: '#f9f9f9',
    tertiary: '#E8EFF2',
  },
  text: {
    primary: '#25383F',
    secondary: '#637A83',
    disabled: '#AFAFAF',
    inverse: '#FFFFFF',
    error: '#F6623A',
    success: '#96BA6E',
    link: '#FF8724',
    warning: ui.warning.main,
  },
};

const chakraUI_internalColors = {
  // used for chakra colorSchema props (passing brand colors to https://smart-swatch.netlify.app/)
  scale: {
    primary: {
      50: '#e9f3ff',
      100: '#ccd8e7',
      200: '#adbed1',
      300: '#8ea4bd',
      400: '#6f8aaa',
      500: '#557190',
      600: '#425871',
      700: '#2e3f52',
      800: '#192633',
      900: '#000e17',
    },
    secondary: {
      50: '#e3f6fd',
      100: '#c8dfe8',
      200: '#a9c8d4',
      300: '#8ab2c2',
      400: '#6a9baf',
      500: '#518296',
      600: '#3d6575',
      700: '#2a4855',
      800: '#142c34',
      900: '#001018',
    },
    accent: {
      50: '#fff8dc',
      100: '#ffeaaf',
      200: '#fedc80',
      300: '#fdce4e',
      400: '#fdc01f',
      500: '#e3a608',
      600: '#b18102',
      700: '#7e5c00',
      800: '#4d3700',
      900: '#1c1200',
    },
  },
  // Color names below are not part of our design system tokens. They are minimum colors needed for Chakra components to work out of the box
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000000',
  white: '#FFFFFF',
  whiteAlpha: {
    '50': 'rgba(255, 255, 255, 0.04)',
    '100': 'rgba(255, 255, 255, 0.06)',
    '200': 'rgba(255, 255, 255, 0.08)',
    '300': 'rgba(255, 255, 255, 0.16)',
    '400': 'rgba(255, 255, 255, 0.24)',
    '500': 'rgba(255, 255, 255, 0.36)',
    '600': 'rgba(255, 255, 255, 0.48)',
    '700': 'rgba(255, 255, 255, 0.64)',
    '800': 'rgba(255, 255, 255, 0.80)',
    '900': 'rgba(255, 255, 255, 0.92)',
  },
  blackAlpha: {
    '50': 'rgba(0, 0, 0, 0.04)',
    '100': 'rgba(0, 0, 0, 0.06)',
    '200': 'rgba(0, 0, 0, 0.08)',
    '300': 'rgba(0, 0, 0, 0.16)',
    '400': 'rgba(0, 0, 0, 0.24)',
    '500': 'rgba(0, 0, 0, 0.36)',
    '600': 'rgba(0, 0, 0, 0.48)',
    '700': 'rgba(0, 0, 0, 0.64)',
    '800': 'rgba(0, 0, 0, 0.80)',
    '900': 'rgba(0, 0, 0, 0.92)',
  },
  gray: {
    '50': '#F7FAFC',
    '100': '#EDF2F7',
    '200': '#E2E8F0',
    '300': '#CBD5E0',
    '400': '#A0AEC0',
    '500': '#718096',
    '600': '#4A5568',
    '700': '#2D3748',
    '800': '#1A202C',
    '900': '#171923',
  },
};

export const designTokens = {
  breakpoints,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  radii,
  sizes,
  shadows,
  space,
  zIndices,
  colors: {
    ...colors,
    ...chakraUI_internalColors,
  },
};
