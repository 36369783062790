import type { CookieCampaignInfo } from '@lib/segment/SegmentContext';
import { parse_utm_querystring_to_object } from '@lib/segment/SegmentContext';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';

/**
 * This implementation works in the same way in web-candidate / web-customer
 */
export function select_utm_cookies_object() {
  const utm_cookie_params: string = Cookies.get('utm_cookie_params') ?? '';
  return parse_utm_querystring_to_object(utm_cookie_params);
}

/**
 * This implementation works in the same way in web-candidate / web-customer
 * We can refactor it to be shared (just keep in mind next-router vs react-router)
 */
export function useSetUtmCookies() {
  const { query } = useRouter();

  const cookies_api = Cookies.withAttributes({
    expires: 365,
    domain:
      // * To make it works at localhost
      typeof window !== 'undefined' && window.location.hostname === 'localhost'
        ? undefined
        : '.terminal.io',
  });

  // TODO: the following conditional is temporary until we deprecate lastTouchParams cookie
  const utm_old_cookie_params = Cookies.get('lastTouchParams');

  if (utm_old_cookie_params) {
    cookies_api.set('utm_cookie_params', utm_old_cookie_params);
    Cookies.remove('lastTouchParams', { path: '/' });
  }
  // TODO: end of temporary conditional

  const has_utm_params_in_query = !!(
    query.utm_campaign ||
    query.utm_medium ||
    query.utm_source ||
    query.utm_content ||
    query.utm_term ||
    query.cid
  );

  if (!(has_utm_params_in_query || query.tracking_id)) return;

  const current_utm_params = select_utm_cookies_object();

  let new_utm_params_to_save: ReturnType<typeof select_utm_cookies_object> | typeof query = {};

  const tracking_id = {
    ...(query.tracking_id || current_utm_params.tracking_id
      ? { tracking_id: query.tracking_id || current_utm_params.tracking_id }
      : {}),
  };

  if (has_utm_params_in_query) {
    new_utm_params_to_save = {
      ...query,
      ...tracking_id,
    };
  } else {
    new_utm_params_to_save = {
      ...current_utm_params,
      ...tracking_id,
    };
  }

  const utm_params_names: (keyof CookieCampaignInfo)[] = [
    'utm_campaign',
    'utm_medium',
    'utm_source',
    'utm_content',
    'utm_term',
    'cid',
    // * This is not a utm param, but we're using it as one
    'tracking_id',
  ];

  const utm_params_query_string = utm_params_names
    .map((key) => (new_utm_params_to_save[key] ? `${key}=${new_utm_params_to_save[key]}` : ''))
    .filter(Boolean)
    .join('&');

  if (utm_params_query_string) {
    cookies_api.set('utm_cookie_params', utm_params_query_string);
    return;
  }
}

/**
 * This implementation works in the same way in web-candidate / web-customer
 */
export function useGetUtmCookies() {
  const utm_cookie_params: string = Cookies.get('utm_cookie_params') ?? '';
  return utm_cookie_params ? `?${utm_cookie_params}` : '';
}
