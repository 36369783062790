import { Heading as DesignSystemHeading } from '@terminal/design-system';
import { HeadingStoryblok, Storyblok } from '../types';
import { storyblokEditable } from '@storyblok/react';

export function Heading({ blok }: Storyblok<HeadingStoryblok>) {
  const { text = '', level, align } = blok;
  const isDisplay = level === 'display';
  return (
    <DesignSystemHeading
      {...storyblokEditable(blok)}
      as={isDisplay ? 'h1' : `h${level || 2}`}
      variant={isDisplay ? 'heading-1' : `heading-${level || '2'}`}
      fontSize={isDisplay ? '7xl' : undefined}
      textAlign={align || 'center'}
    >
      {text}
    </DesignSystemHeading>
  );
}
